import { Schema } from "effect"
import type { FetchUserAttributesOutput } from "aws-amplify/auth"

const AppUserSchema = Schema.rename(
  Schema.Struct({
    email: Schema.String,
    sub: Schema.String,
    idamRoles: Schema.Array(Schema.String),
    name: Schema.String,
  }),
  { sub: "id" },
)

export type AppUser = typeof AppUserSchema.Type

export const parseAppUserFromAmplify = (attrs: FetchUserAttributesOutput) => {
  const atrs = { ...attrs, idamRoles: getIdamRoles(attrs) }
  return Schema.decodeUnknownEither(AppUserSchema)(atrs)
}

const getIdamRoles = (attrs: FetchUserAttributesOutput): Array<string> => {
  const idamRoles = attrs["custom:idam_roles"]

  if (idamRoles) return splitIdamRoles(idamRoles)

  return []
}

const splitIdamRoles = (idamRoles: string): Array<string> => {
  if (idamRoles.match(/\[[^\]]*\]/)) {
    return idamRoles.replace("[", "").replace("]", "").split(", ")
    // Must be in a single group
  }
  return [idamRoles]
}
