import { post } from "~/shared/api/apiFetchers"

type AddRootPayload = {
  AccountId: string
  AccountEmail: string
  AccountOrigin: string
}

export const addRoot = (payload: AddRootPayload) => {
  const url = "/provision/billingroot"

  return post(url, payload)
}

type AddCustomerPayload = Record<string, string | boolean>

export const addCustomer = (payload: AddCustomerPayload) => {
  const url = "/provision/customer"

  return post(url, payload)
}

type AddOrganisationPayload = Record<string, string | boolean>

export const addOrganisation = (payload: AddOrganisationPayload) => {
  const url = "/provision/organisation"

  return post(url, payload)
}

type VerifyOnboardingPayload = {
  AwsAccountEmail: string | null
  AwsAccountId: string
  CustomerName: string | null
}

export const verifyOnboarding = (payload: VerifyOnboardingPayload) => {
  const url = "/provision/onboardingvalidation"

  return post(url, payload)
}
