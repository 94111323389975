import * as log from "~/shared/utils/log"
import type React from "react"
import type { Account } from "~/models/Account"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Option } from "effect"
import { Panel } from "~/shared/ui/Panels"
import { Status } from "~/shared/ui/Status"
import { useAccountBillingRoots } from "~/shared/hooks/useAccountBillingRoots"

type Cell = {
  key: string
  ele: string | React.JSX.Element
}

const getTableRows = (
  accounts: ReadonlyArray<Account>,
): Array<{ key: string; cells: Array<Cell> }> => {
  return accounts.map((account) => {
    const name = Option.getOrElse(account.Name, () => "")
    const orgId = Option.getOrElse(account.OrganisationId, () => "")

    return {
      key: account.Id,
      cells: [
        { key: "name", ele: name },
        { key: "accountId", ele: account.AwsAccountId },
        { key: "email", ele: account.Email },
        { key: "orgId", ele: orgId },
        {
          key: "status",
          ele: <Status key={account.Id} status={account.Status} />,
        },
      ],
    }
  })
}

const headers = [
  { title: "Name" },
  { title: "Account ID" },
  { title: "Email" },
  { title: "Organisation Id" },
  { title: "Status" },
]

export const ProvisionBillingRoots = () => {
  const { remoteData: remoteDataUsedBillingRoots } =
    useAccountBillingRoots("USED")

  if (
    remoteDataUsedBillingRoots._tag === "Loading" ||
    remoteDataUsedBillingRoots._tag === "NotAsked"
  ) {
    return <Loading classname="pb-12" />
  }

  if (remoteDataUsedBillingRoots._tag === "Failure") {
    log.error(remoteDataUsedBillingRoots.error)
    return <NoResults title="Error fetching Accounts" />
  }

  const { Accounts: accounts } = remoteDataUsedBillingRoots.data

  const rows = getTableRows(accounts)

  return (
    <Panel>
      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.key}>
              {row.cells.map((cell: Cell) => (
                <td key={cell.key}>{cell.ele}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Panel>
  )
}
