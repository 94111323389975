import type { Context } from "~/models/Context"
import { Page } from "~/shared/ui/Page"
import { ControlPlane } from "~/widgets/ControlPlane"

const TITLE = "Control Plane"

type Props = {
  context: Context
}

export const PageControlPlane = (props: Props) => {
  return (
    <Page context={props.context} title={TITLE}>
      <ControlPlane context={props.context} />
    </Page>
  )
}
