import * as log from "~/shared/utils/log"
import {
  OrganisationalUnitsResponseSchema,
  type OrganisationalUnit,
} from "~/models/OrganisationalUnit"
import { DateText } from "~/shared/ui/DateText"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Option } from "effect"
import type { Organisation } from "~/models/Organisation"
import { Schema } from "effect"
import { Status } from "~/shared/ui/Status"
import { useQuery } from "~/shared/hooks/useQuery"

const headers = [
  { title: "Id" },
  { title: "Name" },
  { title: "Parent" },
  { title: "Type" },
  { title: "Status" },
  { title: "Modified" },
]

type Props = {
  organisation: Organisation
}

export const CustomerOrganisationalUnitList = (props: Props) => {
  const { organisation } = props

  const organisationId = organisation.Id

  const { remoteData } = useQuery({
    url: `/organisation/${organisationId}/organisational-units`,
    parse: Schema.decodeUnknownEither(OrganisationalUnitsResponseSchema),
  })

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Organisational Units" />
  }

  const { OrganisationalUnits: organisationalUnits } = remoteData.data

  if (organisationalUnits.length === 0) {
    return <NoResults title="No organisational units found" />
  }

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {organisationalUnits.map((unit: OrganisationalUnit) => (
          <tr key={unit.Id}>
            <td>{unit.Id}</td>
            <td>{unit.Name}</td>
            <td>{Option.getOrNull(unit.ParentOrganisationalUnitId)}</td>
            <td>{unit.OrganisationalUnitType}</td>
            <td>
              <Status status={unit.Status} />
            </td>
            <td>
              <DateText dateString={unit.ModifiedTS} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
