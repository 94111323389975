import * as Form from "~/shared/ui/Form"
import * as log from "~/shared/utils/log"
import * as middleware from "~/shared/api/middleware"
import * as navigation from "~/shared/utils/navigation"
import * as provisionApi from "~/shared/api/provisionApiV2"
import * as routes from "~/routes"
import type { Account } from "~/models/Account"
import type { Customer } from "~/models/Customer"
import type { Organisation } from "~/models/Organisation"
import { AWS_SUPPORT_TYPES, CUSTOMER_TYPES } from "~/shared/utils/constants"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { Link } from "~/shared/ui/Link"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Schema } from "effect"
import { StringToBooleanSchema } from "~/shared/utils/parsing"
import { useAccountBillingRoots } from "~/shared/hooks/useAccountBillingRoots"
import { useCustomerOrgs } from "~/shared/hooks/useCustomerOrgs"
import { useCustomers } from "~/shared/hooks/useCustomers"
import {
  useElmish,
  type InitResult,
  type UpdateReturnType,
  type MsgSource,
} from "react-elmish"
import {
  FormFieldV2,
  FormSectionTitle,
  FormSelectV2,
  FormTextInputV2,
  RadioGroup,
  RadioWithLabelV2,
} from "~/shared/ui/Forms"

type MessageSource = MsgSource<"ProvisionAddOrg">

type LocalMessage = { name: "NoOp" } & MessageSource

const ValidFormDataSchema = Schema.Struct({
  customerId: Schema.NonEmptyString,
  customerType: Schema.NonEmptyString,
  marketplaceSignup: StringToBooleanSchema,
  billingRoot: Schema.NonEmptyString,
  orgAlias: Schema.String,
  emailTemplate: Schema.NonEmptyString,
  awsSupportType: Schema.String,
  partnerSupport: StringToBooleanSchema,
  idamUserFirstName: Schema.NonEmptyString,
  idamUserEmail: Schema.NonEmptyString,
  idamUserLastName: Schema.NonEmptyString,
}).pipe(
  Schema.filter((input) => {
    const issues: Array<Schema.FilterIssue> = []

    // TODO use preSelectedCustomer
    const isDirect = input.customerType === CUSTOMER_TYPES.DIRECT
    const hasSupport = input.partnerSupport

    if (!isDirect && !hasSupport) {
      issues.push({
        path: ["partnerSupport"],
        message: "Partned support is required for non-direct customers",
      })
    }

    return issues
  }),
)

type ValidFormData = typeof ValidFormDataSchema.Type

type InputFormData = typeof ValidFormDataSchema.Encoded

type FieldNames = keyof ValidFormData

type ResponseData = unknown

type Message = LocalMessage | Form.Message<FieldNames, ResponseData>

type Model = Form.Model<FieldNames, ResponseData>

type InitProps = Form.InitProps<FieldNames, ValidFormData, ResponseData>

const init = (props: InitProps): InitResult<Model, Message> => {
  return Form.init(props)
}

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.source) {
    case "ProvisionAddOrg": {
      return [model]
    }
    case "Form": {
      return Form.update(model, msg, props)
    }
  }
}

const useCustomerDefaultOrg = (
  selectedCustomerId: string | undefined,
): Organisation | undefined => {
  const { remoteData } = useCustomerOrgs({
    customerId: selectedCustomerId,
  })

  if (selectedCustomerId == null) return undefined

  if (
    remoteData._tag === "Loading" ||
    remoteData._tag === "NotAsked" ||
    remoteData._tag === "Failure"
  ) {
    return undefined
  }

  const { Organisations: orgs } = remoteData.data

  return orgs.find((org) => org.Name === "default")
}

const onSubmit = async (values: ValidFormData) => {
  const payload = {
    CustomerId: values.customerId,
    BillingAccount: values.billingRoot,
    OrgAlias: values.orgAlias,
    MarketplaceSignup: values.marketplaceSignup,
    AwsSupportType: values.awsSupportType,
    IdamUserEmail: values.idamUserEmail,
    IdamUserFirstName: values.idamUserFirstName,
    IdamUserLastName: values.idamUserLastName,
  }

  // Optional payload keys
  if (values.emailTemplate)
    Object.assign(payload, { EmailTemplate: values.emailTemplate })

  if (values.partnerSupport) {
    Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
  } else {
    Object.assign(payload, { AwsPartnerSupport: false })
  }

  const nextRoute =
    values.customerId === ""
      ? routes.routeProvision()
      : routes.routeCustomer(values.customerId)

  const promise = provisionApi.addOrganisation(payload)

  return middleware
    .amplifyJsonResponseToRemoteData(Schema.Any)(promise)
    .then((response) => {
      if (response._tag === "Success") {
        navigation.navigateToRoute(nextRoute)
      }
      return response
    })
}

const makeInitialValues = (
  customerId: string,
  customerType: string,
): InputFormData => {
  return {
    awsSupportType: AWS_SUPPORT_TYPES.BASIC,
    billingRoot: "",
    customerType: customerType,
    customerId,
    emailTemplate: "",
    idamUserEmail: "",
    idamUserFirstName: "",
    idamUserLastName: "",
    marketplaceSignup: "false",
    orgAlias: "",
    partnerSupport: "false",
  }
}

type PropsWithData = {
  customer: Customer
  unusedBillingRoots: ReadonlyArray<Account>
}

const ProvisionAddOrgWithData = (props: PropsWithData) => {
  const { customer, unusedBillingRoots } = props

  const initialValues = makeInitialValues(customer.Id, customer.Type)

  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      initialValues,
      onSubmit,
      validDataSchema: ValidFormDataSchema,
    },
    init,
    update,
    name: "ProvisionAddOrg",
  })

  const defaultOrg = useCustomerDefaultOrg(customer.Id)

  const customerTypeDirect = customer.Type === CUSTOMER_TYPES.DIRECT

  const partnerSupportField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "partnerSupport",
  })

  const marketplaceSignupField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "marketplaceSignup",
  })

  const billingRootField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "billingRoot",
  })

  const orgAliasField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "orgAlias",
  })

  const emailTemplateField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "emailTemplate",
  })

  const awsSupportTypeField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "awsSupportType",
  })

  const idamUserEmailField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserEmail",
  })

  const idamUserFirstNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserFirstName",
  })

  const idamUserLastNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserLastName",
  })

  return (
    <Form.Form dispatch={dispatch} model={model}>
      <div className="py-4">
        Customer: <span className="font-semibold">{customer.Name}</span>
        <Link
          className="ml-2 underline text-blue-500"
          href={routes.pathProvisionAddOrganisation()}
        >
          Change
        </Link>
      </div>

      {customerTypeDirect === false ? (
        <FormFieldV2
          {...partnerSupportField}
          label="Partner Support?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabelV2
              checked={partnerSupportField.value === "true"}
              label="Yes"
              name={partnerSupportField.name}
              onChange={partnerSupportField.onChange}
              value="true"
            />

            <RadioWithLabelV2
              checked={partnerSupportField.value === "false"}
              label="No"
              name={partnerSupportField.name}
              onChange={partnerSupportField.onChange}
              value="false"
            />
          </RadioGroup>
        </FormFieldV2>
      ) : null}

      {defaultOrg ? (
        <FormFieldV2
          error={marketplaceSignupField.error}
          id={marketplaceSignupField.name}
          label="AWS Marketplace Signup?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabelV2
              checked={marketplaceSignupField.value === "true"}
              label="Yes"
              name={marketplaceSignupField.name}
              onChange={marketplaceSignupField.onChange}
              value="true"
            />

            <RadioWithLabelV2
              checked={marketplaceSignupField.value === "false"}
              label="No"
              name={marketplaceSignupField.name}
              onChange={marketplaceSignupField.onChange}
              value="false"
            />
          </RadioGroup>
        </FormFieldV2>
      ) : null}

      <FormSelectV2 {...billingRootField} label="Billing Root" required={true}>
        <option disabled value="">
          Select a billing root
        </option>
        {unusedBillingRoots.map((root: Account) => (
          <option value={root.AwsAccountId} key={root.AwsAccountId}>
            {root.Email}
          </option>
        ))}
      </FormSelectV2>

      <FormTextInputV2 {...orgAliasField} label="Alias" required={true} />

      <FormTextInputV2
        {...emailTemplateField}
        label="Email domain template"
        required={true}
      />

      <FormSelectV2
        {...awsSupportTypeField}
        label="AWS Support Type"
        required={true}
      >
        {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType) => (
          <option value={awsSupportType} key={awsSupportType}>
            {awsSupportType}
          </option>
        ))}
      </FormSelectV2>

      <FormSectionTitle>First IDAM User</FormSectionTitle>

      <FormTextInputV2
        {...idamUserEmailField}
        helpText="Email address of the first IDAM user"
        label="Email"
        required={true}
      />

      <FormTextInputV2
        {...idamUserFirstNameField}
        helpText="First name of the first IDAM user"
        label="First Name"
        required={true}
      />

      <FormTextInputV2
        {...idamUserLastNameField}
        helpText="Last name of the first IDAM user"
        label="Last Name"
        required={true}
      />

      <ButtonOutline type="submit">Create Organisation</ButtonOutline>
    </Form.Form>
  )
}

type Props = {
  customerId: string
  marketplaceSignupValue?: boolean
}

export const ProvisionAddOrgWithCustomer = (props: Props) => {
  const { customerId } = props

  const { remoteData: remoteDataCustomers } = useCustomers()

  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  const isLoadingCustomers =
    remoteDataCustomers._tag === "Loading" ||
    remoteDataCustomers._tag === "NotAsked"

  const isLoadingBillingRoots =
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"

  if (isLoadingCustomers || isLoadingBillingRoots) {
    return <Loading classname="pb-12" />
  }

  if (remoteDataCustomers._tag === "Failure") {
    log.error(remoteDataCustomers.error)
    return <NoResults title="Error fetching Customers" />
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    log.error(remoteDataUnusedBillingRoots.error)
    return <NoResults title="Error fetching Billing Roots" />
  }

  const { Customers: customers } = remoteDataCustomers.data
  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const customer = customers.find((customer) => customer.Id === customerId)

  if (customer == null) {
    return <NoResults title="Customer not found" />
  }

  return (
    <ProvisionAddOrgWithData
      customer={customer}
      unusedBillingRoots={unusedBillingRoots}
    />
  )
}

type PropsWithoutCustomer = {
  marketplaceSignupValue?: boolean
}

export const ProvisionAddOrgWithoutCustomer = (
  _props: PropsWithoutCustomer,
) => {
  const { remoteData: remoteDataCustomers } = useCustomers()

  const isLoadingCustomers =
    remoteDataCustomers._tag === "Loading" ||
    remoteDataCustomers._tag === "NotAsked"

  if (isLoadingCustomers) {
    return <Loading classname="pb-12" />
  }

  if (remoteDataCustomers._tag === "Failure") {
    log.error(remoteDataCustomers.error)
    return <NoResults title="Error fetching Customers" />
  }

  const { Customers: customers } = remoteDataCustomers.data

  const onChange = (customerId: string) => {
    navigation.navigateToRoute(routes.routeCustomerAddOrganisation(customerId))
  }

  return (
    <FormSelectV2
      error={undefined}
      label="Customer"
      name="customer"
      onChange={onChange}
      value=""
    >
      <option disabled value="">
        Select a customer
      </option>
      {customers.map((customer) => (
        <option value={customer.Id} key={customer.Id}>
          {customer.Name}
        </option>
      ))}
    </FormSelectV2>
  )
}
