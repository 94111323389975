import { AccountsResponseSchema, type Account } from "~/models/Account"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "effect"
import { useQuery } from "~/shared/hooks/useQuery"

export const useOrganisationAccounts = (
  orgId: string,
): {
  remoteData: RemoteData<{ Accounts: ReadonlyArray<Account> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/accounts?organisation=${orgId}`,
    parse: Schema.decodeUnknownEither(AccountsResponseSchema),
  })

  return { remoteData }
}
