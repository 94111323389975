import { post } from "~/shared/api/apiFetchers"

export const deploy = async (args: { deploymentId: string }) => {
  const payload = {
    DeploymentId: args.deploymentId,
  }

  return post("/component/deployment", payload)
}

export const lock = async (args: {
  componentName: string
  value: boolean
  comment: string
}) => {
  const payload = {
    Components: [
      { Name: args.componentName, Locked: args.value, Comment: args.comment },
    ],
  }

  return post("/component/lock", payload)
}

export const toggleAutoDeploy = async (args: {
  componentName: string
  value: boolean
}) => {
  const payload = {
    Components: { [args.componentName]: args.value },
  }

  return post("/component/autodeploy", payload)
}
