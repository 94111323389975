import * as log from "~/shared/utils/log"
import * as times from "~/shared/utils/times"
import { Option, DateTime } from "effect"

type Props = {
  dateString: string
  className?: string
}

export const DateText = (props: Props) => {
  const { dateString } = props

  return Option.match(times.parseISO(dateString), {
    onNone: () => {
      log.error(`Invalid date ${dateString}`)
      return null
    },
    onSome: (date) => {
      const formattedDateText = times.formatDate(date)

      const title = DateTime.formatIso(date)

      return (
        <span title={title}>
          <span className={props.className}>{formattedDateText}</span>
        </span>
      )
    },
  })
}
