import * as RadixToast from "@radix-ui/react-toast"
import classNames from "classnames"
import type React from "react"

/**
 * @public
 */
export type Variant = "success" | "warning" | "error" | "info"

type Props = {
  id?: string
  message: React.ReactNode
  variant: Variant
}

export const Toast = (props: Props) => {
  const { message, variant } = props

  const classes = classNames({
    "rounded shadow-md p-2 flex items-center": true,
    "bg-green-100": variant === "success",
    "bg-yellow-100": variant === "warning",
    "bg-blue-100": variant === "info",
    "bg-red-100": variant === "error",
  })

  return (
    <RadixToast.Root id={props.id} className={classes} duration={2500}>
      <RadixToast.Description className="flex p-4">
        {message}
      </RadixToast.Description>
    </RadixToast.Root>
  )
}
