import {
  type Organisation,
  OrganisationsResponseSchema,
} from "~/models/Organisation"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "effect"
import { useQuery } from "~/shared/hooks/useQuery"

export const useCustomerOrgs = (props: {
  customerId: string | undefined
}): {
  remoteData: RemoteData<
    { Organisations: ReadonlyArray<Organisation> },
    unknown
  >
} => {
  const { remoteData } = useQuery({
    parse: Schema.decodeUnknownEither(OrganisationsResponseSchema),
    shouldFetch: props.customerId != null,
    url: `/organisations/${props.customerId}`,
  })

  return { remoteData }
}
