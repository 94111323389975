import * as navigation from "~/shared/utils/navigation"
import * as organisationsApi from "~/shared/api/organisationsApiV2"
import * as routes from "~/routes"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { Fragment } from "react"
import { Loading } from "~/shared/ui/Loading"
import { Notice } from "~/shared/ui/Notices"
import * as RemoteData from "~/models/RemoteData"
import {
  useElmish,
  cmd,
  type InitResult,
  type UpdateReturnType,
} from "react-elmish"
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from "~/shared/ui/Modals"
import { TextInput } from "~/shared/ui/Forms"

type ConfigureResponse = RemoteData.RemoteData<string, string>

type Model = {
  orgAliasInput: string
  configureResponse: ConfigureResponse
}

type Message =
  | { name: "SetOrgAlias"; value: string }
  | { name: "Execute" }
  | { name: "ExecuteDone"; data: ConfigureResponse }
  | { name: "ExecuteFailed" }

type InitProps = { organisationId: string }

const init = (_props: InitProps): InitResult<Model, Message> => {
  return [
    {
      orgAliasInput: "",
      configureResponse: RemoteData.NotAsked(),
    },
  ]
}

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.name) {
    case "SetOrgAlias": {
      return [{ ...model, orgAliasInput: msg.value }]
    }

    case "Execute": {
      const onSuccess = (data: ConfigureResponse): Message => ({
        name: "ExecuteDone",
        data,
      })

      const onFailure = (): Message => ({
        name: "ExecuteFailed",
      })

      const command = cmd.ofEither(
        () => organisationsApi.configureSecurityHub(props.organisationId),
        onSuccess,
        onFailure,
      )

      return [model, command]
    }

    case "ExecuteDone": {
      return [{ ...model, configureResponse: msg.data }]
    }

    case "ExecuteFailed": {
      return [model]
    }
  }
}

export const ConfigureSecurityHubModal = ({
  customerId,
  organisationId,
  orgAlias = "",
}: {
  customerId: string
  organisationId: string
  orgAlias: string
}) => {
  const handleClose = () =>
    navigation.navigateToRoute(
      routes.routeOrganisationServices(customerId, organisationId),
    )

  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: { organisationId },
    init,
    update,
    name: "ConfigureSecurityHubModal",
  })

  const orgAliasMatches = orgAlias !== "" && model.orgAliasInput === orgAlias

  const execute = () => {
    dispatch({ name: "Execute" })
  }

  const setOrgAlias = (value: string) => {
    dispatch({ name: "SetOrgAlias", value })
  }

  return (
    <Modal
      aria-describedby="configure-security-hub-description"
      aria-labelledby="configure-security-hub-title"
      isOpen={true}
      onClose={handleClose}
    >
      <ModalTitle id="configure-security-hub-title">
        Reconfigure Security Hub
      </ModalTitle>

      {model.configureResponse._tag === "Loading" && (
        <ModalContent>
          <Loading />
        </ModalContent>
      )}

      {model.configureResponse._tag === "Success" && (
        <ModalContent>
          <p title={model.configureResponse.data}>
            ✅ Reconfiguring Security Hub...
          </p>

          <p>Please close this dialog and check back later for updates</p>
        </ModalContent>
      )}

      {(model.configureResponse._tag === "NotAsked" ||
        model.configureResponse._tag === "Failure") && (
        <Fragment>
          <ModalContent>
            <Notice>
              You are making changes to the organisation{" "}
              <span
                title={`Org ID: ${organisationId}`}
                className="font-semibold"
              >
                {orgAlias}
              </span>
              's security configuration
            </Notice>

            {model.configureResponse._tag === "Failure" && (
              <Notice variant="Error">{model.configureResponse.error}</Notice>
            )}

            <p className="font-semibold my-2">
              Please type the Organisation's Alias to confirm this change:
            </p>

            <TextInput
              className="w-full"
              onChange={(event) => setOrgAlias(event.target.value)}
              placeholder="Organisation Alias"
              value={model.orgAliasInput}
            />
          </ModalContent>

          <ModalFooter>
            <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>

            <ButtonOutline
              onClick={execute}
              variant={orgAliasMatches ? "Danger" : "Secondary"}
              disabled={!orgAliasMatches}
            >
              Reconfigure
            </ButtonOutline>
          </ModalFooter>
        </Fragment>
      )}
    </Modal>
  )
}
