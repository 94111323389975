import { Schema } from "effect"

const UserSchema = Schema.Struct({
  Email: Schema.String,
  EmailVerified: Schema.OptionFromNullishOr(Schema.Boolean, undefined),
  Enabled: Schema.Boolean,
  Federated: Schema.Boolean,
  FirstName: Schema.String,
  Id: Schema.String,
  LastName: Schema.String,
  MFA: Schema.OptionFromNullishOr(Schema.Boolean, undefined),
  Role: Schema.String,
  RecordCreateOrigin: Schema.String,
})

export type User = typeof UserSchema.Type

export const UsersResponseSchema = Schema.Struct({
  Users: Schema.Array(UserSchema),
})
