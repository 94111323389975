import { Schema } from "effect"

const FeatureSchema = Schema.Record({
  key: Schema.String,
  value: Schema.Boolean,
})

const OrganisationSchema = Schema.Struct({
  Alias: Schema.String,
  AllowedDomains: Schema.OptionFromNullishOr(Schema.String, undefined),
  AwsAccountEmailTemplate: Schema.String,
  // AwsId: 'o-mj0invkjuq',
  AwsPartnerSupport: Schema.Boolean,
  AwsSupportType: Schema.String,
  BillingAccountId: Schema.String,
  CognitoGroupId: Schema.String,
  CognitoUserId: Schema.String,
  ComplianceType: Schema.String,
  CreatedBy: Schema.String,
  CreatedTS: Schema.String,
  CustomerId: Schema.String,
  FactoryVersion: Schema.String,
  Features: Schema.OptionFromNullishOr(FeatureSchema, undefined),
  Id: Schema.String,
  MarketplaceSignup: Schema.Boolean,
  ModifiedTS: Schema.String,
  Name: Schema.String,
  OrganisationType: Schema.String,
  OrgsOuId: Schema.String,
  Region: Schema.String,
  Regions: Schema.Array(Schema.String),
  Status: Schema.String,
  UserTaskId: Schema.String,
})

export type Organisation = typeof OrganisationSchema.Type

export const OrganisationsResponseSchema = Schema.Struct({
  Organisations: Schema.Array(OrganisationSchema),
})
