import * as Form from "~/shared/ui/Form"
import * as middleware from "~/shared/api/middleware"
import * as navigation from "~/shared/utils/navigation"
import * as provisionApi from "~/shared/api/provisionApiV2"
import * as routes from "~/routes"
import type { Account } from "~/models/Account"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Schema } from "effect"
import { StringToBooleanSchema } from "~/shared/utils/parsing"
import { useAccountBillingRoots } from "~/shared/hooks/useAccountBillingRoots"
import {
  CUSTOMER_SUPPORT_PLANS,
  CUSTOMER_TYPES,
  AWS_SUPPORT_TYPES,
} from "~/shared/utils/constants"
import {
  useElmish,
  type InitResult,
  type UpdateReturnType,
  type MsgSource,
} from "react-elmish"
import {
  FormFieldV2,
  FormSectionTitle,
  FormSelectV2,
  FormTextInputV2,
  RadioGroup,
  RadioWithLabelV2,
} from "~/shared/ui/Forms"

type MessageSource = MsgSource<"ProvisionAddCustomer">

type LocalMessage = { name: "NoOp" } & MessageSource

export const ValidFormDataSchema = Schema.Struct({
  customerType: Schema.NonEmptyString,
  partnerSupport: StringToBooleanSchema,
  internalCustomer: StringToBooleanSchema,
  marketplaceSignup: StringToBooleanSchema,
  awsSupportType: Schema.String,
  customerSupportPlan: Schema.NonEmptyString,
  billingRoot: Schema.NonEmptyString,
  companyName: Schema.NonEmptyString,
  staxAlias: Schema.NonEmptyString,
  emailTemplate: Schema.NonEmptyString,
  rootEmail: Schema.NonEmptyString,
  idamUserFirstName: Schema.NonEmptyString,
  idamUserEmail: Schema.NonEmptyString,
  idamUserLastName: Schema.NonEmptyString,
}).pipe(
  Schema.filter((input) => {
    const issues: Array<Schema.FilterIssue> = []

    const isDirect = input.customerType === CUSTOMER_TYPES.DIRECT
    const hasSupport = input.partnerSupport

    if (!isDirect && !hasSupport) {
      issues.push({
        path: ["partnerSupport"],
        message: "Partned support is required for non-direct customers",
      })
    }

    return issues
  }),
)

type ValidFormData = typeof ValidFormDataSchema.Type

export type InputFormData = typeof ValidFormDataSchema.Encoded

type FieldNames = keyof ValidFormData

type ResponseData = unknown

type Message = LocalMessage | Form.Message<FieldNames, ResponseData>

type Model = Form.Model<FieldNames, ResponseData>

type InitProps = Form.InitProps<FieldNames, ValidFormData, ResponseData>

const init = (props: InitProps): InitResult<Model, Message> => {
  return Form.init(props)
}

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.source) {
    case "ProvisionAddCustomer": {
      return [model]
    }
    case "Form": {
      return Form.update(model, msg, props)
    }
  }
}

const onSubmit = async (values: ValidFormData) => {
  const payload = {
    OwnerName: values.companyName,
    Email: values.rootEmail,
    OrgAlias: values.staxAlias,
    CompanyName: values.companyName,
    Type: values.customerType,
    StaxSupportPlan: values.customerSupportPlan,
    AwsSupportType: values.awsSupportType,
    BillingAccount: values.billingRoot,
    MarketplaceSignup: values.marketplaceSignup,
    InternalCustomer: values.internalCustomer,
    IdamUserEmail: values.idamUserEmail,
    IdamUserFirstName: values.idamUserFirstName,
    IdamUserLastName: values.idamUserLastName,
  }

  // Optional payload keys
  if (values.emailTemplate)
    Object.assign(payload, { EmailTemplate: values.emailTemplate })

  if (values.partnerSupport) {
    Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
  } else {
    Object.assign(payload, { AwsPartnerSupport: false })
  }

  const promise = provisionApi.addCustomer(payload)

  return middleware
    .amplifyJsonResponseToRemoteData(Schema.Any)(promise)
    .then((response) => {
      if (response._tag === "Success") {
        navigation.navigateToRoute(routes.RouteProvision())
      }
      return response
    })
}

const initialValues: InputFormData = {
  awsSupportType: AWS_SUPPORT_TYPES.BASIC,
  billingRoot: "",
  companyName: "",
  customerSupportPlan: CUSTOMER_SUPPORT_PLANS.STARTER,
  customerType: CUSTOMER_TYPES.DIRECT,
  emailTemplate: "",
  idamUserEmail: "",
  idamUserFirstName: "",
  idamUserLastName: "",
  internalCustomer: "false",
  marketplaceSignup: "false",
  partnerSupport: "false",
  rootEmail: "",
  staxAlias: "",
}

export const ProvisionAddCustomer = () => {
  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      initialValues,
      onSubmit,
      validDataSchema: ValidFormDataSchema,
    },
    init,
    update,
    name: "ProvisionAddBillingRoot",
  })

  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  const isLoadingBillingRoots =
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"

  if (isLoadingBillingRoots) {
    return <Loading classname="pb-12" />
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    return <NoResults title="Error fetching Billing Roots" />
  }

  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const customerTypeDirect =
    model.formData["customerType"] === CUSTOMER_TYPES.DIRECT

  const customerTypeField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "customerType",
  })

  const partnerSupportField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "partnerSupport",
  })

  const internalCustomerField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "internalCustomer",
  })

  const marketplaceSignupField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "marketplaceSignup",
  })

  const awsSupportTypeField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "awsSupportType",
  })

  const customerSupportPlanField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "customerSupportPlan",
  })

  const billingRootField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "billingRoot",
  })

  const companyNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "companyName",
  })

  const staxAliasField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "staxAlias",
  })

  const emailTemplateField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "emailTemplate",
  })

  const rootEmailField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "rootEmail",
  })

  const idamUserFirstNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserFirstName",
  })

  const idamUserEmailField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserEmail",
  })

  const idamUserLastNameField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "idamUserLastName",
  })

  return (
    <Form.Form dispatch={dispatch} model={model}>
      <FormSelectV2
        label="Customer Type"
        required={true}
        {...customerTypeField}
      >
        {Object.values(CUSTOMER_TYPES).map((custType) => (
          <option value={custType} key={custType}>
            {custType}
          </option>
        ))}
      </FormSelectV2>

      {customerTypeDirect === false ? (
        <FormFieldV2
          error={partnerSupportField.error}
          id={partnerSupportField.name}
          label="Partner Support?"
          required={false}
        >
          <RadioGroup>
            <RadioWithLabelV2
              checked={partnerSupportField.value === "true"}
              label="Yes"
              name={partnerSupportField.name}
              onChange={partnerSupportField.onChange}
              value="true"
            />

            <RadioWithLabelV2
              checked={partnerSupportField.value === "false"}
              label="No"
              name={partnerSupportField.name}
              onChange={partnerSupportField.onChange}
              value="false"
            />
          </RadioGroup>
        </FormFieldV2>
      ) : null}

      <FormFieldV2
        error={internalCustomerField.error}
        id={internalCustomerField.id}
        label="Internal Customer?"
        required={false}
      >
        <RadioGroup>
          <RadioWithLabelV2
            checked={internalCustomerField.value === "true"}
            label="Yes"
            name={internalCustomerField.name}
            onChange={internalCustomerField.onChange}
            value="true"
          />

          <RadioWithLabelV2
            checked={internalCustomerField.value === "false"}
            label="No"
            name={internalCustomerField.name}
            onChange={internalCustomerField.onChange}
            value="false"
          />
        </RadioGroup>
      </FormFieldV2>

      <FormFieldV2
        error={marketplaceSignupField.error}
        id={marketplaceSignupField.name}
        label="AWS Marketplace Signup?"
        required={false}
      >
        <RadioGroup>
          <RadioWithLabelV2
            checked={marketplaceSignupField.value === "true"}
            label="Yes"
            name={marketplaceSignupField.name}
            onChange={marketplaceSignupField.onChange}
            value="true"
          />

          <RadioWithLabelV2
            checked={marketplaceSignupField.value === "false"}
            label="No"
            name={marketplaceSignupField.name}
            onChange={marketplaceSignupField.onChange}
            value="false"
          />
        </RadioGroup>
      </FormFieldV2>

      <FormSelectV2
        label="AWS Support Type"
        required={true}
        helpText="AWS support type for this customer"
        {...awsSupportTypeField}
      >
        {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType) => (
          <option value={awsSupportType} key={awsSupportType}>
            {awsSupportType}
          </option>
        ))}
      </FormSelectV2>

      <FormSelectV2
        label="Stax Support Plan"
        required={true}
        {...customerSupportPlanField}
      >
        {Object.values(CUSTOMER_SUPPORT_PLANS).map((custSupportPlan) => (
          <option value={custSupportPlan} key={custSupportPlan}>
            {custSupportPlan}
          </option>
        ))}
      </FormSelectV2>

      <FormSelectV2
        label="Billing Root"
        required={true}
        {...billingRootField}
        value={billingRootField.value}
      >
        <option disabled value="">
          Select a billing root
        </option>
        {unusedBillingRoots.map((root: Account) => (
          <option value={root.AwsAccountId} key={root.AwsAccountId}>
            {root.Email}
          </option>
        ))}
      </FormSelectV2>

      <FormTextInputV2
        label="Company Name"
        helpText="Name of the company for the Stax Account"
        required={true}
        {...companyNameField}
      />

      <FormTextInputV2
        label="Stax Alias"
        helpText="Stax alias for the customer, e.g. company or company-name"
        required={true}
        {...staxAliasField}
      />

      <FormTextInputV2
        label="Email domain template"
        helpText="Direct and ECAM customers can utilise a template. Template must conform with either CompanyName+${Stax::AccountId}@CompanyDomain.com or ${Stax::AccountId}@CompanyDomain.com"
        required={true}
        {...emailTemplateField}
      />

      <FormTextInputV2
        label="Root Email"
        helpText="Email address for the root cognito user"
        required={true}
        {...rootEmailField}
      />

      <FormSectionTitle>First IDAM User</FormSectionTitle>

      <FormTextInputV2
        helpText="Email address of the first IDAM user"
        label="Email"
        required={true}
        {...idamUserEmailField}
      />

      <FormTextInputV2
        helpText="First name of the first IDAM user"
        label="First Name"
        required={true}
        {...idamUserFirstNameField}
      />

      <FormTextInputV2
        helpText="Last name of the first IDAM user"
        label="Last Name"
        required={true}
        {...idamUserLastNameField}
      />

      <ButtonOutline type="submit" {...Form.submitButtonAttributes(model)}>
        Create Customer
      </ButtonOutline>
    </Form.Form>
  )
}
