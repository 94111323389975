import { post, put } from "~/shared/api/apiFetchers"

export const invokeSaga = (accountId: string) => {
  const url = "/saga/account"
  const payload = { Id: accountId }

  return post(url, payload)
}

export const reonboard = (accountId: string) => invokeSaga(accountId)

export const rediscover = (orgId: string, awsAccountId: string) => {
  const url = "/saga/account/rediscover"
  const payload = { OrganisationId: orgId, AwsAccountId: awsAccountId }

  return post(url, payload)
}

export const upsertRoles = (accountId: string) => {
  const url = `/account/${accountId}/roles`

  return put(url, {})
}
