import { ButtonSolid } from "~/shared/ui/Buttons"
import { signInWithRedirect } from "@aws-amplify/auth"
import type { AppConfig } from "~/shared/utils/config"

const onSignIn = (_config: AppConfig) => {
  signInWithRedirect({
    provider: { custom: "OKTA" },
  })
  return null
}

export const PageAuthLogin = (props: { config: AppConfig }) => {
  const { config } = props

  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "100vh", background: "#EBEFF1" }}
    >
      <div className="bg-white p-16 rounded">
        <div className="flex justify-center pb-6">
          <img
            alt="Stax Logo"
            style={{
              width: "6rem",
              height: "6rem",
            }}
            src="/images/stax-icon-black.svg"
          />
        </div>

        <div className="font-semibold pb-6 text-center">
          Log in to Juma Admin
        </div>

        <ButtonSolid onClick={() => onSignIn(config)}>
          Log in with Okta
        </ButtonSolid>
      </div>
    </div>
  )
}
