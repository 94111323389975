import type { Context } from "~/models/Context"
import { ComponentList } from "~/widgets/ComponentList"
import { Page } from "~/shared/ui/Page"

type Props = {
  context: Context
}

export const PageComponents = (props: Props) => {
  return (
    <Page context={props.context} title="Components">
      <ComponentList context={props.context} />
    </Page>
  )
}
