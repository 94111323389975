import * as log from "~/shared/utils/log"
import * as navigation from "~/shared/utils/navigation"
import * as routes from "~/routes"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { ConfigureRegionsModal } from "./CustomerServiceList/ConfigureRegionsModal"
import { ConfigureSecurityHubModal } from "./CustomerServiceList/ConfigureSecurityHubModal"
import type { Context } from "~/models/Context"
import type { Customer } from "~/models/Customer"
import { DateText } from "~/shared/ui/DateText"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Option } from "effect"
import type { Organisation } from "~/models/Organisation"
import { Status } from "~/shared/ui/Status"
import { useOrganisationServiceConfigurations } from "~/shared/hooks/useOrganisationServiceConfigurations"
import type { ServiceConfiguration } from "~/models/ServiceConfiguration"
import { ConfigureGuardrailsModal } from "./CustomerServiceList/ConfigureGuardrailsModal"

const headers = [
  { title: "Service" },
  { title: "Status" },
  { title: "Created" },
  { title: "Configuration" },
  { title: "Actions" },
]

type Props = {
  context: Context
  customer: Customer
  organisation: Organisation
}

const ServiceConfigurationComp = ({
  service,
}: { service: ServiceConfiguration }) => {
  const configuration = Option.getOrElse(service.Configuration, () => ({}))

  return (
    <details>
      <summary className="font-semibold text-slate-500">Configuration:</summary>
      <pre className="w-full mt-2 p-3 border border-slate-300 rounded bg-slate-100">
        {JSON.stringify(configuration, null, 2)}
      </pre>
    </details>
  )
}

type ActionsProps = {
  service: ServiceConfiguration
}

const Actions = (props: ActionsProps) => {
  if (props.service.Service === "SECURITYHUB") {
    return <SecurityHubActions service={props.service} />
  }

  if (props.service.Service === "REGIONS") {
    return <RegionsActions service={props.service} />
  }

  if (props.service.Service === "GUARDRAILS") {
    return <GuardrailsActions service={props.service} />
  }

  return null
}

export const CustomerServiceList = (props: Props) => {
  const { context, customer, organisation } = props

  const organisationId = organisation.Id

  const { remoteData } = useOrganisationServiceConfigurations(organisationId)

  const customerId = customer.Id

  if (organisation.Status === "SUSPENDED") {
    return <NoResults title="Organisation is suspended" />
  }

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Services" />
  }

  const { ServiceConfigurations: serviceConfigurations } = remoteData.data

  if (serviceConfigurations.length === 0) {
    return <NoResults title="No services found" />
  }

  const showSecurityHubModal = routes.isInCustomerConfigureSecurityHub(
    context.currentAppLocation,
  )

  const showConfigureRegionsModal = routes.isInCustomerConfigureRegion(
    context.currentAppLocation,
  )

  const showGuardrailsModal = routes.isInCustomerConfigureGuardrails(
    context.currentAppLocation,
  )

  return (
    <>
      {showSecurityHubModal ? (
        <ConfigureSecurityHubModal
          organisationId={organisationId}
          customerId={customerId}
          orgAlias={organisation.Alias || ""}
        />
      ) : null}

      {showConfigureRegionsModal ? (
        <ConfigureRegionsModal
          organisationId={organisationId}
          customerId={customerId}
          orgAlias={organisation.Alias || ""}
        />
      ) : null}

      {showGuardrailsModal ? (
        <ConfigureGuardrailsModal
          organisationId={organisationId}
          customerId={customerId}
          orgAlias={organisation.Alias || ""}
        />
      ) : null}

      <table className="DataTable">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {serviceConfigurations.map((service) => (
            <tr key={service.Id}>
              <td>
                <div className="pt-3">{service.Service}</div>
              </td>
              <td>
                <div className="pt-3">
                  <Status status={service.Status} />
                </div>
              </td>
              <td>
                <div className="pt-3">
                  <DateText dateString={service.CreatedTS} />
                </div>
              </td>
              <td width={"40%"}>
                <div className="pt-3">
                  <ServiceConfigurationComp service={service} />
                </div>
              </td>
              <td>
                <Actions service={service} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const GuardrailsActions = (props: {
  service: ServiceConfiguration
}) => {
  const configureGuardrails = () => {
    navigation.navigate(routes.customerConfigureGuardrailsRoute)
  }

  if (props.service.Status === "ACTIVE") {
    return (
      <ButtonOutline onClick={configureGuardrails} variant="Danger">
        Configure
      </ButtonOutline>
    )
  }

  return <div>Configurable Guardrails not enabled by customer.</div>
}

const SecurityHubActions = (props: {
  service: ServiceConfiguration
}) => {
  const configureSecurityHub = () => {
    const url = routes.urlCustomerConfigureSecurityHub()
    navigation.navigate(url)
  }

  if (props.service.Service !== "SECURITYHUB") return null

  if (props.service.Status === "ACTIVE") {
    return (
      <ButtonOutline onClick={configureSecurityHub} variant="Danger">
        Configure
      </ButtonOutline>
    )
  }

  return <div>Users must self-service to activate Security Hub</div>
}

const RegionsActions = (props: {
  service: ServiceConfiguration
}) => {
  const { service } = props

  const configureRegions = () => {
    const url = routes.urlCustomerConfigureRegion()
    navigation.navigate(url)
  }

  if (service.Service !== "REGIONS") return null

  return (
    <ButtonOutline onClick={configureRegions} variant="Danger">
      Configure
    </ButtonOutline>
  )
}
