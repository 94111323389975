import * as log from "~/shared/utils/log"
import * as RemoteData from "~/models/RemoteData"
import * as times from "~/shared/utils/times"
import type { Context } from "~/models/Context"
import { ComponentBuild } from "./ComponentBuild"
import { ComponentDeploySwitch } from "./ComponentDeploySwitch"
import { ComponentLockSwitch } from "./ComponentLockSwitch"
import { ComponentTime } from "./ComponentTime"
import { isDev } from "~/shared/utils/stages"
import { Loading } from "~/shared/ui/Loading"
import { Option } from "effect"
import { Schema } from "effect"
import { Status } from "~/shared/ui/Status"
import { useQuery } from "~/shared/hooks/useQuery"
import { useElmish, type InitResult, type UpdateReturnType } from "react-elmish"
import {
  type Component,
  type Comment,
  type DeploymentStatus,
  type Deployment,
  DeploymentSchema,
} from "~/models/Component"

const DeploymentListResponseSchema = Schema.Struct({
  Deployments: Schema.Array(DeploymentSchema),
})

type DeploymentListResponse = typeof DeploymentListResponseSchema.Type

const getCommentIsAfter = (prev: Comment, current: Comment): boolean => {
  const prevCommentCreated = times.parseISOOrNull(prev.CreatedTS)

  const currentCreated = times.parseISOOrNull(current.CreatedTS)

  if (prevCommentCreated == null) return false

  if (currentCreated == null) return false

  return times.isAfter(prevCommentCreated, currentCreated)
}

const getDeploymentIsAfter = (
  deployment: Deployment,
  activeDeployment: Deployment,
): boolean => {
  const deploymentCreated = times.parseISOOrNull(deployment.CreatedTS)

  const activeDeploymentCreated = times.parseISOOrNull(
    activeDeployment.CreatedTS,
  )

  if (deploymentCreated == null) return false

  if (activeDeploymentCreated == null) return false

  return times.isAfter(deploymentCreated, activeDeploymentCreated)
}

type Message = {
  name: "LoadedDeployments"
  remoteData: RemoteData.RemoteData<DeploymentListResponse, unknown>
}

type Model = {
  deployments: RemoteData.RemoteData<DeploymentListResponse, unknown>
  isLoading: boolean
}

const init = (props: Props): InitResult<Model, Message> => {
  return [
    {
      deployments: RemoteData.NotAsked(),
      isLoading: props.isLoading,
    },
  ]
}

const update = (
  model: Model,
  msg: Message,
  _props: Props,
): UpdateReturnType<Model, Message> => {
  switch (msg.name) {
    case "LoadedDeployments":
      return [{ ...model, deployments: msg.remoteData }]
  }
}

type Props = {
  component: Component
  context: Context
  deploymentStatuses: { [key: string]: DeploymentStatus }
  failing: Array<Component>
  isLoading: boolean
  isTogglingAutoDeploy: boolean
  onAutoDeployComponent: (component: Component, value: boolean) => void
  onDeployComponent: (deploymentId: string) => void
  onLockComponent: (
    component: Component,
    lock: boolean,
    comment: string,
  ) => void
}

export const ComponentItemContent = (props: Props) => {
  const { component, failing } = props

  const [model, dispatch] = useElmish<Props, Model, Message>({
    props,
    init,
    update,
    name: "ComponentItemContent",
  })

  const parse = Schema.decodeUnknownEither(DeploymentListResponseSchema)

  useQuery({
    url: `/component/${component.Name}/deployments?limit=25`,
    parse,
    onData: (
      remoteData: RemoteData.RemoteData<DeploymentListResponse, unknown>,
    ) => {
      dispatch({ name: "LoadedDeployments", remoteData })
    },
  })

  const activeDeployment = Option.getOrNull(component.ActiveDeployment)

  const comments = Option.getOrElse(component.Comments, () => [])

  const blocking = failing.filter(
    (item) => item.DeploymentOrder < component.DeploymentOrder,
  )

  if (model.isLoading) return <Loading />

  if (
    model.deployments._tag === "NotAsked" ||
    model.deployments._tag === "Loading"
  ) {
    return <Loading />
  }

  if (model.deployments._tag === "Failure") {
    log.error(model.deployments.error)
    return <div>Error</div>
  }

  const { Deployments: deployments } = model.deployments.data

  return (
    <div className="t-component-item-content py-4">
      <div className="t-item-header border-b border-slate-300 pb-4 px-4">
        <div className="flex font-semibold">
          <div>{component.Name}</div>
          <div>{!component.DeployPassing && <Status status={"FAILING"} />}</div>
        </div>

        <div>
          {activeDeployment ? (
            <ComponentTime
              key={component.Id}
              time={activeDeployment.ModifiedTS}
            />
          ) : (
            <span>No active deployment</span>
          )}
          <div>
            {blocking && blocking.length > 0
              ? `Blocked by ${blocking[0]?.Name}`
              : undefined}
          </div>
        </div>

        <div className="t-actions py-2 space-x-4">
          <ComponentDeploySwitch
            key={component.Id}
            component={component}
            context={props.context}
            disabled={!isDev()}
            isBusy={props.isTogglingAutoDeploy}
            isChecked={component.AutoDeploy}
            onAutoDeployComponent={props.onAutoDeployComponent}
          />
          <ComponentLockSwitch
            key={component.Id}
            component={component}
            context={props.context}
            enabled={component.Locked}
            onLockComponent={props.onLockComponent}
          />
          <div className="flex">
            {comments && comments.length > 0
              ? `${
                  comments.reduce((prev, current) => {
                    if (prev) {
                      return getCommentIsAfter(prev, current) ? prev : current
                    }
                    return current
                  }).Comment
                }`
              : undefined}
          </div>
        </div>
      </div>

      {activeDeployment ? (
        <div className="pb-4">
          <ComponentBuild
            activeVersion={activeDeployment.Version}
            collapseActive={false}
            context={props.context}
            deployment={activeDeployment}
            deploymentStatuses={props.deploymentStatuses}
            isCurrent={true}
            isNew={false}
            key={component.Id}
            locked={component.Locked}
            onDeployComponent={props.onDeployComponent}
          />
        </div>
      ) : null}

      <ul className="t-list">
        {component &&
          deployments.map((deployment) => {
            if (!deployment) return null

            const isAfter = activeDeployment
              ? getDeploymentIsAfter(deployment, activeDeployment)
              : false

            const isNew = activeDeployment && isAfter

            const isCurrent =
              activeDeployment && activeDeployment.Id === deployment.Id

            return (
              <li key={deployment.Id}>
                <ComponentBuild
                  activeVersion={activeDeployment?.Version}
                  collapseActive={true}
                  context={props.context}
                  deployment={deployment}
                  deploymentStatuses={props.deploymentStatuses}
                  isCurrent={isCurrent || false}
                  isNew={isNew || false}
                  key={component.Id}
                  locked={component.Locked}
                  onDeployComponent={props.onDeployComponent}
                />
              </li>
            )
          })}
      </ul>
    </div>
  )
}
