import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/shared/ui/Panels"
import { Page } from "~/shared/ui/Page"
import { ProvisionVerification } from "~/widgets/ProvisionVerification"

type Props = {
  context: Context
}

export const PageProvisionVerification = (props: Props) => (
  <Page context={props.context} title="Verify Onboarding">
    <NarrowContainerWithPadding>
      <p>Verify the onboarding status of an existing AWS Account into Stax</p>
      <ProvisionVerification />
    </NarrowContainerWithPadding>
  </Page>
)
