import * as log from "~/shared/utils/log"
import { CustomerTable } from "./CustomerList/CustomerTable"
import { Array as EArray, Order } from "effect"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { useCustomers } from "~/shared/hooks/useCustomers"

const headers = ["Customer", "Support Level", "Type", "Status"]

export const CustomerList = () => {
  const { remoteData } = useCustomers()

  if (remoteData._tag === "NotAsked" || remoteData._tag === "Loading") {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Customers" />
  }

  const { data } = remoteData
  const { Customers: customers } = data

  if (customers.length < 1) {
    return <NoResults title="No Customers" />
  }

  const sortedCustomers = EArray.sortWith(
    customers,
    (c) => {
      // Always show at the top
      if (c.Name === "Stax Control Plane") {
        return ""
      }

      // Tilde (~) has a high character code so all inactive customers are weighted towards the end
      if (c.Status !== "ACTIVE") {
        return "~"
      }

      // Space ( ) is a lowish character code so internal customers are weighted towards the start
      if (c.Internal === true) {
        return " "
      }

      // All other customers are sorted as is, in the middle of the list
      return c.Name
    },
    Order.string,
  )

  return <CustomerTable customers={sortedCustomers} headers={headers} />
}
