import * as log from "~/shared/utils/log"
import type React from "react"
import {
  WorkloadCataloguesResponseSchema,
  type WorkloadCatalogue,
} from "~/models/WorkloadCatalogue"
import { Status } from "~/shared/ui/Status"
import { Loading } from "~/shared/ui/Loading"
import { ClickToCopy } from "~/shared/ui/ClickToCopy"
import type { Organisation } from "~/models/Organisation"
import { useQuery } from "~/shared/hooks/useQuery"
import { Schema } from "effect"
import { NoResults } from "~/shared/ui/NoResults"

const headers = [{ title: "Name" }, { title: "Status" }, { title: "ID" }]

type Cell = {
  key: string
  ele: string | React.JSX.Element
}

const gettableRows = (
  items: ReadonlyArray<WorkloadCatalogue>,
): Array<{ key: string; cells: Array<Cell> }> => {
  return items.map((item) => ({
    key: item.Id,
    cells: [
      { key: "name", ele: item.Name },
      { key: "status", ele: <Status key="status" status={item.Status} /> },
      {
        key: "id",
        ele: (
          <span>
            {item.Id} <ClickToCopy text={item.Id} />
          </span>
        ),
      },
    ],
  }))
}

type Props = {
  organisation: Organisation
}

export const CustomerCatalogue = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const { remoteData } = useQuery({
    url: `/workload-catalogue/${organisationId}`,
    parse: Schema.decodeUnknownEither(WorkloadCataloguesResponseSchema),
  })

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Catalogues" />
  }

  const { WorkloadCatalogue: catalogues } = remoteData.data

  const rows = gettableRows(catalogues)

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell: Cell) => (
              <td key={cell.key}>{cell.ele}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
