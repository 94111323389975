import { type Customer, CustomersResponseSchema } from "~/models/Customer"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "effect"
import { useQuery } from "~/shared/hooks/useQuery"

export const useCustomers = (): {
  remoteData: RemoteData<{ Customers: ReadonlyArray<Customer> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: "/customers",
    parse: Schema.decodeUnknownEither(CustomersResponseSchema),
  })

  return { remoteData }
}
