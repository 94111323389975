import * as queryString from "~/shared/utils/queryString"
import * as router from "~/shared/utils/router"
import type { Location } from "history"
import { Data, Option, Either, pipe } from "effect"
import { Schema } from "effect"

type Empty = Record<never, never>

export type AppLocation = {
  route: AppRoute
  query: queryString.Query
}

export type AppRoute = Data.TaggedEnum<{
  RouteNotFound: Empty
  RouteRoot: Empty
  RouteDevShowcase: Empty
  RouteInAuth: { readonly sub: RouteAuth }
  RouteComponents: Empty
  RouteComponent: { readonly name: string }
  RouteControlPlane: Empty
  RouteCustomers: Empty
  RouteInCustomer: {
    readonly customerId: string
    readonly sub: CustomerRoute
  }
  RouteInOrganisation: {
    readonly customerId: string
    readonly orgId: string
    readonly sub: OrganisationRoute
  }
  RouteProvision: Empty
  RouteProvisionAddBillingRoot: Empty
  RouteProvisionAddCustomer: Empty
  RouteProvisionAddOrganisation: Empty
  RouteProvisionVerify: Empty
}>

export const {
  RouteRoot,
  RouteNotFound,
  RouteDevShowcase,
  RouteInAuth,
  RouteComponents,
  RouteComponent,
  RouteControlPlane,
  RouteCustomers,
  RouteInCustomer,
  RouteInOrganisation,
  RouteProvision,
  RouteProvisionAddBillingRoot,
  RouteProvisionAddCustomer,
  RouteProvisionAddOrganisation,
  RouteProvisionVerify,
} = Data.taggedEnum<AppRoute>()

type RouteAuth = Data.TaggedEnum<{
  RouteAuthLogin: Empty
  RouteAuthCallback: Empty
  RouteAuthSignOut: Empty
}>

export const { RouteAuthLogin, RouteAuthCallback, RouteAuthSignOut } =
  Data.taggedEnum<RouteAuth>()

type CustomerRoute = Data.TaggedEnum<{
  RouteCustomerShow: Empty
  RouteCustomerAddOrganisation: Empty
  RouteInOrganisation: {
    readonly orgId: string
    readonly sub: OrganisationRoute
  }
}>

export const { RouteCustomerShow, RouteCustomerAddOrganisation } =
  Data.taggedEnum<CustomerRoute>()

export type OrganisationRoute = Data.TaggedEnum<{
  RouteOrganisationShow: Empty
  RouteOrganisationWorkloads: Empty
  RouteOrganisationCatalogue: Empty
  RouteOrganisationUsers: Empty
  RouteOrganisationServices: Empty
  RouteOrganisationPolicies: Empty
  RouteOrganisationOrganisationalUnits: Empty
}>

export const {
  RouteOrganisationShow,
  RouteOrganisationWorkloads,
  RouteOrganisationCatalogue,
  RouteOrganisationUsers,
  RouteOrganisationServices,
  RouteOrganisationPolicies,
  RouteOrganisationOrganisationalUnits,
} = Data.taggedEnum<OrganisationRoute>()

const ACTION_CONFIGURE_REGION = "configure-region"
const ACTION_CONFIGURE_GUARDRAILS = "configure-guardrails"
const ACTION_CONFIGURE_SECURITY_HUB = "configure-security-hub"

const schemaComponent = Schema.Struct({
  name: Schema.String,
})

type Component = typeof schemaComponent.Type

const withComponent =
  (fn: (name: string) => AppRoute) => (params: router.Params) => {
    const result = Schema.decodeUnknownEither(schemaComponent)(params)

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data: Component) => fn(data.name),
    })
  }

const schemaCustomerId = Schema.Struct({
  customerId: Schema.String,
})

const withCustomerId =
  (fn: (cusId: string) => AppRoute) => (params: router.Params) => {
    const result = Schema.decodeUnknownEither(schemaCustomerId)(params)

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data) => fn(data.customerId),
    })
  }

const schemaCustomerIdAndOrgId = Schema.Struct({
  customerId: Schema.String,
  orgId: Schema.String,
})

type CustomerIdAndOrgId = typeof schemaCustomerIdAndOrgId.Type

const withCustomerIdAndOrgId =
  (fn: (cusId: string, orgId: string) => AppRoute) =>
  (params: router.Params) => {
    const result = Schema.decodeUnknownEither(schemaCustomerIdAndOrgId)(params)

    return Either.match(result, {
      onLeft: () => RouteNotFound(),
      onRight: (data: CustomerIdAndOrgId) => fn(data.customerId, data.orgId),
    })
  }

const routes: Array<router.RouteDef<AppRoute>> = [
  {
    name: "RouteRoot",
    path: "",
    make: () => RouteRoot(),
  },
  {
    name: "RouteDevShowcase",
    path: "/dev/showcase",
    make: () => RouteDevShowcase(),
  },
  // Auth
  {
    name: "RouteInAuth.RouteAuthLogin",
    path: "/auth/login",
    make: () => RouteInAuth({ sub: RouteAuthLogin() }),
  },
  {
    name: "RouteInAuth.RouteAuthCallback",
    path: "/auth/redirect",
    make: () => RouteInAuth({ sub: RouteAuthCallback() }),
  },
  // Path needs to match was is configured in Cognito
  {
    name: "RouteInAuth.RouteAuthSignOut",
    path: "/signout",
    make: () => RouteInAuth({ sub: RouteAuthSignOut() }),
  },
  // Components
  {
    name: "RouteComponents",
    path: "/components",
    make: () => RouteComponents(),
  },
  {
    name: "RouteComponent",
    path: "/components/:name",
    make: withComponent((name) => RouteComponent({ name })),
  },
  // ControlPlane
  {
    name: "RouteControlPlane",
    path: "/controlplane",
    make: () => RouteControlPlane(),
  },
  // Customers
  {
    name: "RouteCustomers",
    path: "/customers",
    make: () => RouteCustomers(),
  },
  {
    path: "/customers/:customerId",
    children: [
      {
        name: "RouteInCustomer.RouteCustomerShow",
        path: "",
        make: withCustomerId((customerId) =>
          RouteInCustomer({
            customerId,
            sub: RouteCustomerShow(),
          }),
        ),
      },
      {
        name: "RouteInCustomer.RouteCustomerAddOrganisation",
        path: "/organisations/new",
        make: withCustomerId((customerId) =>
          RouteInCustomer({
            customerId,
            sub: RouteCustomerAddOrganisation(),
          }),
        ),
      },
    ],
  },
  {
    path: "/customers/:customerId/organisations/:orgId",
    children: [
      {
        path: "",
        children: [
          {
            name: "RouteInOrganisation.RouteOrganisationShow",
            path: "",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationShow(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationWorkloads",
            path: "/workloads",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationWorkloads(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationCatalogue",
            path: "/catalogue",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationCatalogue(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationUsers",
            path: "/users",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationUsers(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationServices",
            path: "/services",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationServices(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationPolicies",
            path: "/policies",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationPolicies(),
              }),
            ),
          },
          {
            name: "RouteInOrganisation.RouteOrganisationOrganisationalUnits",
            path: "/organisational-units",
            make: withCustomerIdAndOrgId((customerId, orgId) =>
              RouteInOrganisation({
                customerId,
                orgId,
                sub: RouteOrganisationOrganisationalUnits(),
              }),
            ),
          },
        ],
      },
    ],
  },
  // Provision
  {
    name: "RouteProvision",
    path: "/provision",
    make: () => RouteProvision(),
  },
  {
    name: "RouteProvisionAddBillingRoot",
    path: "/provision/billing-roots/new",
    make: () => RouteProvisionAddBillingRoot(),
  },
  {
    name: "RouteProvisionAddCustomer",
    path: "/provision/customers/new",
    make: () => RouteProvisionAddCustomer(),
  },
  {
    name: "RouteProvisionAddOrganisation",
    path: "/provision/organisations/new",
    make: () => RouteProvisionAddOrganisation(),
  },
  {
    name: "RouteProvisionVerify",
    path: "/provision/verify",
    make: () => RouteProvisionVerify(),
  },
]

export const resolveRoute = (pathname: string): AppRoute => {
  const resolve = router.resolver(routes)
  const result = resolve(pathname)

  return Option.match(result, {
    onNone: () => RouteNotFound(),
    onSome: (route) => route,
  })
}

export const resolveLocation = (location: Location): AppLocation => {
  const route = resolveRoute(location.pathname)
  const query = queryString.parse(location.search)

  return {
    route,
    query,
  }
}

const pathGenerator = router.generator(routes)

const generateAndUnwrap = (name: string, args: router.Params = {}): string => {
  const result = pathGenerator(name, args)

  if (result._tag === "GenerateNotFound") {
    return "/404"
  }

  if (result._tag === "GenerateError") {
    return "/"
  }

  if (result._tag === "GenerateOk") {
    return result.path
  }

  return "/"
}

export const generate = (route: AppRoute): string => {
  const topName = route._tag

  if (topName === "RouteInAuth") {
    const name = `${topName}.${route.sub._tag}`
    return generateAndUnwrap(name)
  }

  if (topName === "RouteInCustomer") {
    const name = `${topName}.${route.sub._tag}`
    const customerId = route.customerId
    const args = { customerId }
    return generateAndUnwrap(name, args)
  }

  if (route._tag === "RouteInOrganisation") {
    const name = `${topName}.${route.sub._tag}`
    const customerId = route.customerId
    const orgId = route.orgId
    const args = { customerId, orgId }
    return generateAndUnwrap(name, args)
  }

  if (topName === "RouteComponent") {
    const args = { name: route.name }
    return generateAndUnwrap(topName, args)
  }

  return generateAndUnwrap(topName)
}

export const generateWithHost = (host: string, route: AppRoute) => {
  return `${host}${generate(route)}`
}

export const urlCustomerConfigureRegion = () =>
  `?action=${ACTION_CONFIGURE_REGION}`

export const urlCustomerConfigureSecurityHub = () =>
  `?action=${ACTION_CONFIGURE_SECURITY_HUB}`

export const customerConfigureGuardrailsRoute = `?action=${ACTION_CONFIGURE_GUARDRAILS}`

// Get routes
export const routeAuthSignIn = () => RouteInAuth({ sub: RouteAuthLogin() })

export const routeAuthSignOut = () => RouteInAuth({ sub: RouteAuthSignOut() })

export const routeAuthCallback = () => RouteInAuth({ sub: RouteAuthCallback() })

export const routeDashboard = () => RouteRoot()

export const routeComponent = (name: string): AppRoute =>
  RouteComponent({ name })

export const routeCustomer = (customerId: string) =>
  RouteInCustomer({ customerId, sub: RouteCustomerShow() })

export const routeOrganisation = (customerId: string, orgId: string) =>
  RouteInOrganisation({
    customerId,
    orgId,
    sub: RouteOrganisationShow(),
  })

export const routeCustomerAddOrganisation = (customerId: string) => {
  return RouteInCustomer({ customerId, sub: RouteCustomerAddOrganisation() })
}

export const routeOrganisationServices = (customerId: string, orgId: string) =>
  RouteInOrganisation({
    customerId,
    orgId,
    sub: RouteOrganisationServices(),
  })

export const routeProvision = () => {
  return RouteProvision()
}

export const routeProvisionAddCustomer = () => {
  return RouteProvisionAddCustomer()
}

export const routeProvisionAddOrganisation = () => {
  return RouteProvisionAddOrganisation()
}

export const routeProvisionVerify = () => {
  return RouteProvisionVerify()
}

// Fetch paths

export const pathProvisionAddOrganisation = () =>
  generate(RouteProvisionAddOrganisation())

// Route assertions

export const isAuthLogin = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthLogin"
}

export const isAuthSignOut = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthSignOut"
}

export const isAuthCallback = (route: AppRoute): boolean => {
  return route._tag === "RouteInAuth" && route.sub._tag === "RouteAuthCallback"
}

export const isInCustomerConfigureSecurityHub = (
  currentLocation: AppLocation,
): boolean => {
  const { route, query } = currentLocation
  const isInOrganisation = route._tag === "RouteInOrganisation"
  const action = getActionFromQuery(query)

  return isInOrganisation && action === ACTION_CONFIGURE_SECURITY_HUB
}

export const isInCustomerConfigureRegion = (
  currentLocation: AppLocation,
): boolean => {
  const { route, query } = currentLocation
  const isInOrganisation = route._tag === "RouteInOrganisation"
  const action = getActionFromQuery(query)

  return isInOrganisation && action === ACTION_CONFIGURE_REGION
}

export const isInCustomerConfigureGuardrails = (
  currentLocation: AppLocation,
): boolean => {
  const { route, query } = currentLocation
  const isInOrganisation = route._tag === "RouteInOrganisation"
  const action = getActionFromQuery(query)

  return isInOrganisation && action === ACTION_CONFIGURE_GUARDRAILS
}

const getActionFromQuery = (query: queryString.Query) => {
  return pipe(query, queryString.getStringParam("action"), Either.getOrNull)
}
