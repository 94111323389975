import { WorkloadsResponseSchema, type Workload } from "~/models/Workload"
import type { RemoteData } from "~/models/RemoteData"
import { Schema } from "effect"
import { useQuery } from "~/shared/hooks/useQuery"

export const useOrganisationWorkloads = (
  orgId: string,
): {
  remoteData: RemoteData<{ Workloads: ReadonlyArray<Workload> }, unknown>
} => {
  const { remoteData } = useQuery({
    url: `/workloads/${orgId}`,
    parse: Schema.decodeUnknownEither(WorkloadsResponseSchema),
  })

  return { remoteData }
}
