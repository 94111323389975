import * as log from "~/shared/utils/log"
import * as routes from "../routes"
import { LinkButtonOutline } from "~/shared/ui/Buttons"
import { Can, Role } from "~/shared/ui/Can"
import type { Context } from "~/models/Context"
import { CustomerList } from "~/widgets/CustomerList"
import { Page, PageFailure, PageIsLoading } from "~/shared/ui/Page"
import { useAccountBillingRoots } from "~/shared/hooks/useAccountBillingRoots"

type Props = {
  context: Context
}

const TITLE = "Customers"

export const PageCustomerList = (props: Props) => {
  const { remoteData: remoteDataUnusedBillingRoots } =
    useAccountBillingRoots("UNUSED")

  if (
    remoteDataUnusedBillingRoots._tag === "Loading" ||
    remoteDataUnusedBillingRoots._tag === "NotAsked"
  ) {
    return <PageIsLoading context={props.context} title={TITLE} />
  }

  if (remoteDataUnusedBillingRoots._tag === "Failure") {
    log.error(remoteDataUnusedBillingRoots.error)
    return (
      <PageFailure
        context={props.context}
        message="Error fetching Accounts"
        title={TITLE}
      />
    )
  }

  const { Accounts: unusedBillingRoots } = remoteDataUnusedBillingRoots.data

  const numActiveBillingRoots = unusedBillingRoots.length

  const actions = (
    <div>
      <Can
        currentUser={props.context.currentUser}
        requiredRole={Role.deployer}
        yes={
          <div>
            {numActiveBillingRoots > 0 ? (
              <LinkButtonOutline
                href={routes.generate(routes.RouteProvisionAddCustomer())}
              >
                + New Customer
              </LinkButtonOutline>
            ) : null}
          </div>
        }
        no={
          <div>
            <LinkButtonOutline
              disabled={true}
              href="#"
              title="You aren't allowed to create new customers"
            >
              + New Customer
            </LinkButtonOutline>
          </div>
        }
      />
    </div>
  )

  return (
    <Page context={props.context} title={TITLE} actions={actions}>
      <CustomerList />
    </Page>
  )
}
