import * as Form from "~/shared/ui/Form"
import * as middleware from "~/shared/api/middleware"
import * as provisionApi from "~/shared/api/provisionApiV2"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { FormTextInputV2, FormSelectV2 } from "~/shared/ui/Forms"
import { Schema } from "effect"
import { validateAwsIdV2, validateEmailV2 } from "~/shared/utils/provision"
import {
  useElmish,
  type InitResult,
  type UpdateReturnType,
  type MsgSource,
} from "react-elmish"

enum ACCOUNT_ORIGIN {
  STAX = "STAX",
  EXTERNAL = "EXTERNAL",
}

type MessageSource = MsgSource<"ProvisionAddBillingRoot">

type LocalMessage = { name: "NoOp" } & MessageSource

const ValidFormDataSchema = Schema.Struct({
  accountEmail: Schema.String.pipe(Schema.filter(validateEmailV2)),
  accountId: Schema.String.pipe(Schema.filter(validateAwsIdV2)),
  accountOrigin: Schema.String,
})

type ValidFormData = typeof ValidFormDataSchema.Type

type InputFormData = typeof ValidFormDataSchema.Encoded

type FieldNames = keyof ValidFormData

type ResponseData = unknown

type Message = LocalMessage | Form.Message<FieldNames, ResponseData>

type Model = Form.Model<FieldNames, ResponseData>

type InitProps = Form.InitProps<FieldNames, ValidFormData, ResponseData>

const init = (props: InitProps): InitResult<Model, Message> => {
  return Form.init(props)
}

const update = (
  model: Model,
  msg: Message,
  props: InitProps,
): UpdateReturnType<Model, Message> => {
  switch (msg.source) {
    case "ProvisionAddBillingRoot": {
      return [model]
    }
    case "Form": {
      return Form.update(model, msg, props)
    }
  }
}

const onSubmit = async (values: ValidFormData) => {
  const payload = {
    AccountId: values.accountId,
    AccountEmail: values.accountEmail,
    AccountOrigin: values.accountOrigin,
  }

  const promise = provisionApi.addRoot(payload)

  return middleware.amplifyJsonResponseToRemoteData(Schema.Any)(promise)
}

const initialValues: InputFormData = {
  accountEmail: "",
  accountId: "",
  accountOrigin: ACCOUNT_ORIGIN.STAX,
}

export const ProvisionAddBillingRoot = () => {
  const [model, dispatch] = useElmish<InitProps, Model, Message>({
    props: {
      initialValues,
      onSubmit,
      validDataSchema: ValidFormDataSchema,
    },
    init,
    update,
    name: "ProvisionAddBillingRoot",
  })

  const accountOriginField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "accountOrigin",
  })

  const accountEmailField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "accountEmail",
  })

  const accountIdField = Form.formFieldAttributes({
    dispatch,
    model,
    fieldName: "accountId",
  })

  return (
    <Form.Form dispatch={dispatch} model={model}>
      <FormSelectV2
        label="Account Origin"
        required={true}
        {...accountOriginField}
      >
        {Object.values(ACCOUNT_ORIGIN).map((origin) => (
          <option value={origin} key={origin}>
            {origin}
          </option>
        ))}
      </FormSelectV2>

      <FormTextInputV2
        label="AWS Account Email"
        required={true}
        {...accountEmailField}
      />

      <FormTextInputV2
        label="AWS Account Id"
        required={true}
        {...accountIdField}
      />

      <ButtonOutline type="submit" {...Form.submitButtonAttributes(model)}>
        Add billing root
      </ButtonOutline>
    </Form.Form>
  )
}
