import { Schema } from "effect"

export const StringToBooleanSchema = Schema.transform(
  // Source schema
  Schema.String,
  // Target schema
  Schema.Boolean,
  {
    strict: true,
    decode: (value) => value === "true",
    encode: (value) => (value ? "true" : "false"),
  },
)
