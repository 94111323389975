import { Schema } from "effect"
import { Option } from "effect"

const AssuranceStepSchema = Schema.Struct({
  Id: Schema.String,
  Name: Schema.String,
  Status: Schema.String,
})

const AccountSchema = Schema.Struct({
  AccountRole: Schema.String,
  AccountType: Schema.OptionFromNullishOr(Schema.String, undefined),
  AssuranceState: Schema.String,
  AssuranceSteps: Schema.optionalToRequired(
    Schema.Array(AssuranceStepSchema),
    Schema.Array(AssuranceStepSchema),
    {
      decode: (input) => {
        if (Option.isNone(input)) {
          return []
        }
        return input.value
      },
      encode: (a) => Option.some(a),
    },
  ),
  AwsAccountId: Schema.String,
  AwsAccountStatusId: Schema.OptionFromNullishOr(Schema.String, undefined),
  AwsLoginURL: Schema.String,
  BillingAccountId: Schema.OptionFromNullishOr(Schema.String, undefined),
  CreatedBy: Schema.OptionFromNullishOr(Schema.String, undefined),
  CreatedTS: Schema.String,
  Email: Schema.String,
  FactoryVersion: Schema.OptionFromNullishOr(Schema.String, undefined),
  Id: Schema.String,
  LatestCost: Schema.OptionFromNullishOr(
    Schema.Union(Schema.String, Schema.Number),
    undefined,
  ),
  ModifiedTS: Schema.String,
  Name: Schema.OptionFromNullishOr(Schema.String, undefined),
  OrganisationId: Schema.OptionFromNullishOr(Schema.String, undefined),
  OrgsOuId: Schema.OptionFromNullishOr(Schema.String, undefined),
  Origin: Schema.String,
  Status: Schema.String,
  StaxCreated: Schema.Boolean,
  UserTaskId: Schema.OptionFromNullishOr(Schema.String, undefined),
})

export type Account = typeof AccountSchema.Type

export const AccountsResponseSchema = Schema.Struct({
  Accounts: Schema.Array(AccountSchema),
})
