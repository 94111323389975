import * as routes from "~/routes"

export type NavLinkName =
  | "customers"
  | "components"
  | "provision"
  | "controlplane"

type NavLink = {
  route: routes.AppRoute
  title: string
  name: NavLinkName
}

export const NavLinks: Array<NavLink> = [
  {
    name: "customers",
    route: routes.RouteCustomers(),
    title: "Customers",
  },
  {
    name: "components",
    route: routes.RouteComponents(),
    title: "Components",
  },
  {
    name: "provision",
    route: routes.RouteProvision(),
    title: "Provision",
  },
  {
    name: "controlplane",
    route: routes.RouteControlPlane(),
    title: "Control Plane",
  },
]
