import type { Context } from "~/models/Context"
import { controlPlaneUpsertOrganisationRoles } from "~/shared/api/controlPlaneAPI"
import { FactoryButton } from "~/shared/ui/FactoryButton"
import { Can, Role } from "~/shared/ui/Can"
import { Panel } from "~/shared/ui/Panels"

type Props = {
  context: Context
}

export const ControlPlane = (props: Props) => {
  const runControlPlaneOrganisationUpsertRoles = () => {
    const confirmation = window.prompt(
      `This action will update the roles for ALL AWS Accounts in ALL AWS Organisations.\n
        You should only be performing this action if you understand the impact.\n
        Are you sure you want to do this?\n
        If you are sure, type 'YES' below:\n`,
    )

    if (confirmation === "YES") {
      return controlPlaneUpsertOrganisationRoles()
    }

    return undefined
  }

  return (
    <Panel>
      <div className="text-slate-500 pr-4">Stax Roles</div>

      <div title="Upserts the Stax roles to all AWS Accounts in the Organisation">
        <Can
          currentUser={props.context.currentUser}
          requiredRole={Role.admin}
          yes={
            <FactoryButton
              label="Upsert All Organisations' Roles"
              disabled={false}
              onClick={() => runControlPlaneOrganisationUpsertRoles()}
              successMessage="Queued"
            />
          }
          no={
            <FactoryButton
              label="Upsert All Organisations' Roles"
              disabled={true}
            />
          }
        />
      </div>
    </Panel>
  )
}
