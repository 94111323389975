import { Can, Role } from "~/shared/ui/Can"
import React from "react"
import { ButtonOutline } from "~/shared/ui/Buttons"
import type { Component } from "~/models/Component"
import type { Context } from "~/models/Context"
import { InlineControlAndLabel, FormTextInput } from "~/shared/ui/Forms"
import { Switch } from "~/shared/ui/Switch"

import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from "~/shared/ui/Modals"

type State = {
  comment: string | null
  enabled: boolean
  open: boolean
}

type Props = {
  enabled: boolean
  component: Component
  context: Context
  onLockComponent: (
    component: Component,
    lock: boolean,
    comment: string,
  ) => void
}

export const ComponentLockSwitch = (props: Props) => {
  const { enabled, component } = props

  const [state, setState] = React.useState<State>({
    comment: "",
    enabled: enabled,
    open: false,
  })

  // This looks like a mistake as we don't change the checked value here
  // The checked value is set after the confirmation dialog
  const handleChange = (_checked: boolean) => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      props.onLockComponent(component, !state.enabled, state.comment)

      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <Can
      currentUser={props.context.currentUser}
      requiredRole={Role.deployer}
      yes={
        <React.Fragment>
          <InlineControlAndLabel label="Lock Deploy" name="lock-deploy">
            <Switch
              checked={state.enabled}
              disabled={false}
              name="lock-deploy"
              onChange={handleChange}
            />
          </InlineControlAndLabel>

          <Modal
            isOpen={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <ModalTitle id="form-dialog-title">Lock</ModalTitle>
            {!state.enabled ? (
              <ModalContent>
                <p className="pb-2">
                  To lock this component a comment is required.
                </p>
                <FormTextInput
                  id="comment"
                  label="comment"
                  onChange={updateText}
                  required
                  value={state.comment ? state.comment : ""}
                />
              </ModalContent>
            ) : (
              <ModalContent>
                <p className="pb-2">
                  To unlock this component a comment is required.
                </p>
                c
                <FormTextInput
                  id="comment"
                  label="comment"
                  onChange={updateText}
                  required
                  value={state.comment ? state.comment : ""}
                />
              </ModalContent>
            )}
            <ModalFooter>
              <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>
              {state.enabled ? (
                <ButtonOutline onClick={toggleLock}>Unlock</ButtonOutline>
              ) : (
                <ButtonOutline onClick={toggleLock}>Lock</ButtonOutline>
              )}
            </ModalFooter>
          </Modal>
        </React.Fragment>
      }
      no={
        <InlineControlAndLabel label="Lock Deploy" name="lock-deploy">
          <Switch
            checked={state.enabled}
            disabled={true}
            name="lock-deploy"
            onChange={() => null}
          />
        </InlineControlAndLabel>
      }
    />
  )
}
