import classNames from "classnames"
import type { PropsWithChildren } from "react"
import { Link } from "~/shared/ui/Link"

type Empty = Record<never, never>

type LabelProps = PropsWithChildren<Empty>

const ButtonLabel = (props: LabelProps) => (
  <span className="font-semibold text-xs min-h-6 flex items-center">
    {props.children}
  </span>
)

type Variant = "Primary" | "Secondary" | "Danger"

type ButtonProps = PropsWithChildren<{
  className?: string
  disabled?: boolean | undefined
  onClick?: () => void
  title?: string | undefined
  type?: "button" | "submit"
  variant?: Variant
}>

export const ButtonOutline = (props: ButtonProps) => {
  const classes = getButtonOutlineClasses(props)

  return (
    <button
      className={classes}
      disabled={props.disabled ?? false}
      onClick={props.onClick}
      title={props.title}
      type={props.type ?? "button"}
    >
      <ButtonLabel>{props.children}</ButtonLabel>
    </button>
  )
}

const getButtonOutlineClasses = (
  props: {
    className?: string
    variant?: Variant
    disabled?: boolean | undefined
  },
  extraClassName?: string,
) => {
  const variant = props.variant ?? "Primary"

  return classNames(
    {
      "t-button": true,
      "t-button-primary": variant === "Primary",
      "t-button-secondary": variant === "Secondary",
      "t-button-danger": variant === "Danger",
      "font-semibold py-2 px-6 rounded uppercase": true,
      "border rounded": true,
      // Enabled
      "cursor-pointer": !props.disabled,
      "border-sky-700 text-sky-700": variant === "Primary" && !props.disabled,
      "border-slate-600 text-slate-700":
        variant === "Secondary" && !props.disabled,
      "border-red-600 text-red-700": variant === "Danger" && !props.disabled,
      // Disabled
      "border-slate-300 text-gray-400":
        (variant === "Primary" || variant === "Secondary") && props.disabled,
      "border-red-300 text-gray-400": variant === "Danger" && props.disabled,
    },
    props.className,
    extraClassName,
  )
}

export const ButtonSolid = (props: ButtonProps) => {
  const variant = props.variant ?? "Primary"

  const classes = classNames(
    {
      "t-button": true,
      "t-button-primary": variant === "Primary",
      "t-button-secondary": variant === "Secondary",
      "t-button-danger": variant === "Danger",
      "font-semibold py-2 px-6 rounded uppercase": true,
      // Enabled
      "cursor-pointer": !props.disabled,
      "bg-sky-700 text-white": variant === "Primary" && !props.disabled,
      "bg-slate-600 text-white": variant === "Secondary" && !props.disabled,
      "bg-red-600 text-white": variant === "Danger" && !props.disabled,
      // Disabled
      "bg-slate-300 text-gray-100":
        (variant === "Primary" || variant === "Secondary") && props.disabled,
      "bg-red-300 text-gray-100": variant === "Danger" && props.disabled,
    },
    props.className,
  )

  return (
    <button
      className={classes}
      disabled={props.disabled ?? false}
      onClick={props.onClick}
      title={props.title}
      type={props.type ?? "button"}
    >
      <ButtonLabel>{props.children}</ButtonLabel>
    </button>
  )
}

type LinkButtonProps = PropsWithChildren<{
  disabled?: boolean
  href: string
  title?: string | undefined
}>

export const LinkButtonOutline = (props: LinkButtonProps) => {
  const classes = getButtonOutlineClasses(props, "inline-block")

  return (
    <Link
      className={classes}
      disabled={props.disabled ?? false}
      href={props.href}
      title={props.title}
    >
      <ButtonLabel>{props.children}</ButtonLabel>
    </Link>
  )
}
