import * as log from "~/shared/utils/log"
import type React from "react"
import type { Organisation } from "~/models/Organisation"
import type { Workload } from "~/models/Workload"
import { ClickToCopy } from "~/shared/ui/ClickToCopy"
import { Loading } from "~/shared/ui/Loading"
import { NoResults } from "~/shared/ui/NoResults"
import { Status } from "~/shared/ui/Status"
import { useOrganisationWorkloads } from "~/shared/hooks/useOrganisationWorkloads"

const headers = [
  { title: "Name" },
  { title: "Modified" },
  { title: "Status" },
  { title: "ID" },
  { title: "AccountId" },
  { title: "Region" },
]

type Cell = {
  key: string
  ele: string | React.JSX.Element
}

const getTableRows = (
  workloads: ReadonlyArray<Workload>,
): Array<{ key: string; cells: Array<Cell> }> => {
  return workloads.map((item) => ({
    key: item.Id,
    cells: [
      { key: "name", ele: item.Name },
      { key: "modified", ele: item.ModifiedTS },
      { key: "status", ele: <Status key="status" status={item.Status} /> },
      {
        key: "id",
        ele: (
          <span>
            {item.Id} <ClickToCopy text={item.Id} />
          </span>
        ),
      },
      { key: "accountId", ele: item.AccountId },
      { key: "region", ele: item.Region },
    ],
  }))
}

type Props = {
  organisation: Organisation
}

export const CustomerWorkloads = (props: Props) => {
  const { organisation } = props
  const organisationId = organisation.Id

  const { remoteData } = useOrganisationWorkloads(organisationId)

  const isLoading =
    remoteData._tag === "Loading" || remoteData._tag === "NotAsked"

  if (isLoading) {
    return <Loading classname="pb-12" />
  }

  if (remoteData._tag === "Failure") {
    log.error(remoteData.error)
    return <NoResults title="Error fetching Workloads" />
  }

  const { Workloads: workloads } = remoteData.data

  const rows = getTableRows(workloads)

  return (
    <table className="DataTable">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.title}>{header.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.cells.map((cell: Cell) => (
              <td key={cell.key}>{cell.ele}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
