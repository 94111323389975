import type { Context } from "~/models/Context"
import { NarrowContainerWithPadding } from "~/shared/ui/Panels"
import { Page } from "~/shared/ui/Page"
import {
  ProvisionAddOrgWithCustomer,
  ProvisionAddOrgWithoutCustomer,
} from "~/widgets/ProvisionAddOrg"

type Props = {
  context: Context
  customerId?: string
}

export const PageProvisionAddOrg = (props: Props) => {
  const { customerId } = props

  return (
    <Page context={props.context} title="Provision">
      <NarrowContainerWithPadding>
        <h2 className="text-lg pb-4">
          Add new organisation to a Stax customer
        </h2>
        {customerId ? (
          <ProvisionAddOrgWithCustomer customerId={customerId} />
        ) : (
          <ProvisionAddOrgWithoutCustomer />
        )}
      </NarrowContainerWithPadding>
    </Page>
  )
}
