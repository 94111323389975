import * as RemoteData from "~/models/RemoteData"
import type { SWRResponse } from "swr"
import type { ApiJsonResponse } from "~/shared/api/apiFetchers"
import type { DocumentType } from "@aws-amplify/core/internals/utils"

export const queryToRemoteData = <Err>(
  query: SWRResponse<ApiJsonResponse, Err>,
): RemoteData.RemoteData<DocumentType, Err> => {
  if (query.isLoading) {
    return RemoteData.Loading()
  }

  if (query.error) {
    return RemoteData.Failure({ error: query.error })
  }

  if (query.data) {
    return RemoteData.Success({ data: query.data.json })
  }

  return RemoteData.NotAsked()
}
