import * as organisationsApi from "~/shared/api/organisationsApiV2"
import React from "react"
import { ButtonOutline } from "~/shared/ui/Buttons"
import { CanAny, Role } from "~/shared/ui/Can"
import type { Context } from "~/models/Context"
import { FormTextInput } from "~/shared/ui/Forms"
import { Switch } from "~/shared/ui/Switch"
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
} from "~/shared/ui/Modals"

interface State {
  comment: string | null
  enabled: boolean
  open: boolean
}

type Props = {
  className?: string
  context: Context
  enabled: boolean
  orgId: string
}

export const LocalUserInviteSwitch = (props: Props) => {
  const { enabled, orgId } = props

  const [state, setState] = React.useState<State>({
    comment: "",
    enabled: enabled,
    open: false,
  })

  const handleChange = () => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      organisationsApi.setConfig(
        orgId,
        "EnableLocalUserInvite",
        !state.enabled,
        state.comment,
      )

      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <CanAny
      currentUser={props.context.currentUser}
      requiredRoles={[Role.customersupport, Role.deployer]}
      yes={
        <React.Fragment>
          <Switch
            checked={state.enabled}
            name="invite"
            onChange={handleChange}
          />
          <Modal
            isOpen={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <ModalTitle id="form-dialog-title">
              Enable Local User Invites
            </ModalTitle>
            {!state.enabled ? (
              <ModalContent>
                <p>To enable local user invites a comment is required.</p>
                <FormTextInput
                  required
                  value={state.comment ? state.comment : ""}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                />
              </ModalContent>
            ) : (
              <ModalContent>
                <p>To disable local user invites a comment is required.</p>
                <FormTextInput
                  required
                  value={state.comment ? state.comment : ""}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                />
              </ModalContent>
            )}
            <ModalFooter>
              <ButtonOutline onClick={handleClose}>Cancel</ButtonOutline>
              {state.enabled ? (
                <ButtonOutline onClick={toggleLock}>Disable</ButtonOutline>
              ) : (
                <ButtonOutline onClick={toggleLock}>Enable</ButtonOutline>
              )}
            </ModalFooter>
          </Modal>
        </React.Fragment>
      }
      no={
        <div
          title={`You don't have permission to ${
            state.enabled ? "disable" : "enable"
          } local user invites.`}
        >
          <Switch
            checked={state.enabled}
            disabled={true}
            name="invite"
            onChange={() => null}
          />
        </div>
      }
    />
  )
}
